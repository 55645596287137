import React from "react";

import appLogo from "../images/teaching_dog_logo.webp";

import appleStoreBadge from "../images/apple_store_badge.svg";
import googlePlayBadge from "../images/google_play_badge.png";

import coverImage from "../images/teaching_dog.webp";

// endorsement images
// import dreamhub_filled from "../images/dreamhub_filled.png";
// import voiceqna_filled from "../images/voiceqna_fill_v2_gradient.png";
// import voicemirror_filled from "../images/voice_mirror_v_1024.png";
// import bazipaipai_unfilled from "../images/bazipaipai_64.png";
// import spindrifthome_filled from "../images/sh_unfill_1024.png";

// section images
// import guitar from "../images/guitar.jpg";
// import event_phones from "../images/event_phones.jpeg";
// import foggy_blue from "../images/foggy_blue.jpg";
// import purple_phones from "../images/purple_phones.png";
// import purple_light from "../images/purple_light.jpg";
// import concert_classic from "../images/concert_classic.jpg";

import trainings from "../images/trainings.png";
import fragebogen from "../images/fragebogen.png";

// import discordImage from "../images/discord.png";

export const initialState = {
  // when in dev, change appURL to local url
  // appURL: 'http://localhost:3000',
  // when in production, change appURL to real url
  appURL: "https://pawcontrol.hl-dev.de",

  appLogo: appLogo,
  appName: "Paw Control",

  coverTitle: "Paw Control App",
  coverText: "type and train your dog!",
  appleStoreBadge: appleStoreBadge,
  //   appleStoreLink: "https://apps.apple.com/us/app/amplichat/id1499570373",
  appleStoreLink: "https://pawcontrol.hl-dev.de/",
  googlePlayBadge: googlePlayBadge,
  //   googlePlayLink: "https://apps.apple.com/us/app/amplichat/id1499570373",
  googlePlayLink: "https://pawcontrol.hl-dev.de/",

  coverImage: coverImage,

  //   endorsementTitle: `Hangout with your favorite people on your favorite apps`,
  //   endorsementText: `AmpliChat powers conversations within DreamHub, VoiceQnA, VoiceMirror, BaZiPaiPai, and SpindriftHome.`,
  //   endorsementList: [
  // {
  //   title: `DreamHub: Visualized Stories`,
  //   titleColor: `orangeRed`,
  //   image: dreamhub_filled,
  //   URL: `https://dreamhub.app`,
  // },
  // {
  //   title: `VoiceQnA: Speak a New Language`,
  //   titleColor: `forestGreen`,
  //   image: voiceqna_filled,
  //   URL: `https://voiceqna.com`,
  // },
  // {
  //   title: `VoiceMirror: Travel Translator`,
  //   titleColor: `blue`,
  //   image: voicemirror_filled,
  //   URL: `https://voiceqna.com/mirror`,
  // },
  // {
  //   title: `BaZiPaiPai: Know Your Destiny`,
  //   titleColor: `black`,
  //   image: bazipaipai_unfilled,
  //   URL: `https://bazipaipai.com`,
  // },
  // {
  //   title: `SpindriftHome: HOA Management`,
  //   titleColor: `orangeRed`,
  //   image: spindrifthome_filled,
  //   URL: `https://spindrifthome.com`,
  // },
  //   ],

  sectionList: [
    {
      title: `Videotrainingsbibliothek`,
      text: `Bereiten Sie sich auf ein flexibles Trainingserlebnis vor, das sich Ihrem Lebensstil anpasst! Mit unserer App haben Sie Zugriff auf eine Vielzahl von Hundetrainingsvideos, die speziell darauf ausgerichtet sind, Ihnen und Ihrem Vierbeiner auf jedem Schritt der Trainingsreise zur Seite zu stehen. Um sicherzustellen, dass Sie immer und überall trainieren können, auch wenn Sie gerade kein Internet haben, ermöglicht unsere App das Herunterladen der Videos direkt auf Ihr Smartphone. So haben Sie Ihre Trainingshilfen stets griffbereit – ob im Park, im Urlaub oder einfach im eigenen Garten. Starten Sie noch heute und machen Sie das Training mit Ihrem Hund so einfach und zugänglich wie nie zuvor!`,
      image: trainings,
    },
    {
      title: `Fragebogen`,
      text: `Entdecken Sie, welcher Typ Ihr Hund wirklich ist! Unser interaktiver Fragebogen führt Sie durch eine Reihe gezielter Fragen, die sorgfältig entworfen wurden, um die einzigartigen Persönlichkeitsmerkmale und Verhaltensweisen Ihres Hundes zu erfassen. Direkt nach der Beantwortung der Fragen erhalten Sie einen detaillierten Bericht, der Ihnen nicht nur verrät, zu welchem Hundetyp Ihr Vierbeiner gehört, sondern Ihnen auch wertvolle Einsichten und maßgeschneiderte Empfehlungen bietet, wie Sie Ihren Hund am besten unterstützen und trainieren können. Machen Sie den ersten Schritt zu einem glücklicheren und gesünderen Zusammenleben mit Ihrem Hund – ganz einfach und verständlich. Probieren Sie es jetzt aus und verstehen Sie Ihren Hund wie nie zuvor!`,
      image: fragebogen,
    },
    // {
    //   title: `Expand Your Event Community`,
    //   text: `Meet like-minded people and share your excitement for the latest events and concerts.`,
    //   image: event_phones,
    // },
    // {
    //   title: `Enhance Event Experience`,
    //   text: `Get instant access to a community of passionate event and concert-goers with our app! Chat with others before, during, and after the event to enhance your experience and create memories that last a lifetime.`,
    //   image: foggy_blue,
    // },
    // {
    //   title: `Chat with Attendees`,
    //   text: `Don't let social anxiety get in the way of enjoying your favorite events and concerts! With our app, you can chat with others and make new friends in a safe, welcoming environment.`,
    //   image: purple_phones,
    // },
    // {
    //   title: `Discover New Events and Friends`,
    //   text: `Our app is the perfect way to enhance your experience at events and concerts! Connect with others, share your thoughts and opinions, and discover new artists and events to love.`,
    //   image: purple_light,
    // },
    // {
    //   title: `Connect with Concert Fans`,
    //   text: `Whether you're a seasoned concert-goer or a first-time attendee, our app is the perfect way to connect with others and make the most of your experience. Download now and start chatting!`,
    //   image: concert_classic,
    // },
  ],

  //   discordImage: discordImage,
  //   discordLink: "https://discord.com/invite/aFQPYyAVDq",
};

const initialContext = {
  state: initialState,
  dispatch: () => null,
};

export const Context = React.createContext(initialContext);
